import { qsa } from "./utils.js"

export function initServices() {
  const targetGroupButtons = qsa(".services__button")
  const services = qsa("[data-target-groups]")

  if (!targetGroupButtons.length) return

  function onlyShow(tg) {
    for (let el of services) {
      el.classList.toggle(
        "card--hidden",
        !el.dataset.targetGroups.includes(`${tg},`),
      )
    }
    for (let el of targetGroupButtons) {
      el.classList.toggle("active", el.getAttribute("href").slice(1) === tg)
    }
  }

  window.addEventListener(
    "hashchange",
    () => {
      onlyShow(location.hash.slice(1))
    },
    false,
  )

  const tg = location.hash.slice(1)
  if (tg) {
    onlyShow(tg)
  } else {
    onlyShow(targetGroupButtons[0].getAttribute("href").slice(1))
  }
}
