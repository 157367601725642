import "./reset.css"
import "./grid.css"
import "./flow.css"
import "./functional.css"
import "./icons.css"

import "./google-fonts-1690989826972.css"
import "./vars.css"
import "./body.css"
import "./admin.css"

import "./arc.css"
import "./buttons.css"
import "./cells.css"
import "./f3cc.css"
import "./customers.css"
import "./details.css"
import "./embeds.css"
import "./faq.css"
import "./footer.css"
import "./logo.css"
import "./menu.css"
import "./messages.css"
import "./prose.css"
import "./card.css"
import "./services.css"
import "./target-groups.css"
import "./team.css"
import "./schema.css"
import "./personal-advice.css"
import "./header-banner.css"
import "./downloads.css"
import "./conserving_hints.css"
import "./procedure.css"
import "./collaboration.css"

import { setDetailsHeights } from "./details.js"
import { onReady, qs, qsa } from "./utils.js"

import "./forms.css"
import { initForms } from "./forms.js"
onReady(initForms)

import { initServices } from "./services.js"
onReady(initServices)

import "./textareas.css"
import { initTextareas } from "./textareas.js"
onReady(initTextareas)

onReady(() => {
  setDetailsHeights("details")
  window.addEventListener("load", () => setDetailsHeights("details"))
})

onReady(() => {
  qsa(".prose table").forEach((table) => {
    const wrapper = document.createElement("div")
    wrapper.className = "table-wrapper"
    table.after(wrapper) /* Add wrapper after table */
    wrapper.append(table) /* Move table into wrapper */
  })
})

onReady(() => {
  const submenu = document.getElementById("submenu")
  const toggle = qs("#sl-toggle"),
    eh = qs("#expand-menu")
  if (toggle && eh) {
    toggle.addEventListener("click", () => {
      eh.checked = !eh.checked

      if (eh.checked) {
        document.body.style.overflow = "hidden"
      } else {
        submenu.classList.remove("open")
        document.body.style.overflow = ""
      }
    })
  }
})

onReady(() => {
  const menuItems = document.getElementById("sl-menu").children
  const submenu = document.getElementById("submenu")
  const header = document.getElementById("header")

  let serviceListItem = null

  const closeMenu = () => {
    submenu.classList.remove("open")
    if (serviceListItem) serviceListItem.classList.remove("rotate")
  }

  const openMenu = () => {
    submenu.classList.add("open")
    if (serviceListItem) serviceListItem.classList.add("rotate")
  }

  for (let item of menuItems) {
    if (item.dataset.type === "service-list") {
      serviceListItem = item
      item.addEventListener("click", (e) => {
        e.preventDefault()
      })
      item.addEventListener("mouseenter", openMenu)
    } else {
      item.addEventListener("mouseenter", closeMenu)
    }
  }
  header.addEventListener("mouseleave", closeMenu)

  submenu.addEventListener("transitionend", (e) => {
    if (!serviceListItem) return

    if (e.propertyName === "grid-template-rows") {
      if (e.target.classList.contains("open")) {
        serviceListItem.addEventListener("click", closeMenu, { once: true })
      } else {
        serviceListItem.addEventListener("click", openMenu, { once: true })
      }
    }
  })
})

onReady(() => {
  const hints = document.getElementsByClassName("ch__hints")

  for (let index = 0; index < hints.length; index++) {
    const hintContainer = hints[index]
    const buttonGroups = hintContainer.querySelectorAll(".ch__button-group")
    const hintChildren = hintContainer.children
    const hintLength = hintChildren.length

    for (let hintIndex = 0; hintIndex < hintLength; hintIndex++) {
      const usedIndex = hintIndex + 1
      buttonGroups.forEach((element, buttonIndex) => {
        const newButton = document.createElement("button")
        newButton.classList.add("ch__button")
        newButton.dataset.index = usedIndex
        newButton.innerText = `Tipp ${usedIndex}`
        if (hintIndex === buttonIndex) newButton.classList.add("active")
        element.appendChild(newButton)

        newButton.addEventListener("click", (e) => {
          for (let i = 0; i < hintChildren.length; i++) {
            hintChildren[i].classList.remove("displayed")
          }
          const button = e.target
          const clickedIndex = button.getAttribute("data-index")
          const newActive = hintContainer.querySelector(
            `.ch__hint[data-index='${clickedIndex}']`,
          )
          newActive.classList.add("displayed")
        })
      })
    }
  }
})
