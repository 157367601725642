export function setDetailsHeights(selector, wrapper = document) {
  const ResizeObserver = window.ResizeObserver

  if (!ResizeObserver) return

  const setHeights = (details) => {
    const initiallyOpen = details.open
    details.dataset.width = details.offsetWidth
    details.open = true
    details.style.setProperty("--expanded", `${details.offsetHeight}px`)
    details.open = false
    details.style.setProperty("--collapsed", `${details.offsetHeight}px`)
    details.open = initiallyOpen
    details.classList.add("with-transition")
  }
  const allDetails = wrapper.querySelectorAll(selector)
  const RO = new ResizeObserver((entries) => {
    return entries.forEach((entry) => {
      const details = entry.target
      const width = parseInt(details.dataset.width, 10)
      if (width !== details.offsetWidth) {
        details.removeAttribute("style")
        setHeights(details)
      }
    })
  })
  allDetails.forEach((details) => {
    RO.observe(details)
  })
}
